import React, { useEffect, useState } from "react";
import Navbar from "../../component/Navbar/navbar";
import { CyberinsuranceDashboard } from "./dashboard";
import InputForm from "./company_input";
import Questions from "./questions";
import { BACKEND_URL } from "../../data/urls";
import ResultsTable from "./resultsTable";

export default function Demo() {
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // Track current page
  const [companyData, setCompanyData] = useState({}); // Collect data from forms
  const [questionData, setQuestionData] = useState({}); // Collect data from forms
  // Separate states for technical and non-technical data
  const [technicalData, setTechnicalData] = useState([]);
  const [nonTechnicalData, setNonTechnicalData] = useState([]);
  const [technicalScore, setTechnicalScore] = useState(0);
  const [nonTechnicalScore, setNonTechnicalScore] = useState(0);
  const [technicalLoading, setTechLoading] = useState(false); // Loading state for API calls
  const [nonTechnicalLoading, setNonTechLoading] = useState(false); // Loading state for API calls

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPageIndex]);

  // Handle form submissions
  const handleCompanyFormSubmit = async (data) => {
    console.log("Company data:", data);
    setCompanyData({ ...companyData, ...data });
    setCurrentPageIndex(1);

    try {
      setTechLoading(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${BACKEND_URL}/companies/check-technical-score`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          // Token expired or invalid
          localStorage.removeItem("token");
          window.location.href = "/login";
          return;
        }
        throw new Error("Failed to submit form data.");
      }

      const result = await response.json();
      setTechnicalData(result.technical_results);
      setTechnicalScore(result.technical_score || 0);
      console.log("Technical data submitted:", result);
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setTechLoading(false);
    }
  };

  const handleQuestionsFormSubmit = async (data) => {
    setQuestionData({ ...questionData, ...data });
    setCurrentPageIndex(2);

    try {
      setNonTechLoading(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${BACKEND_URL}/companies/check-questions-score`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ domain: companyData.domain, answers: data }),
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          // Token expired or invalid
          localStorage.removeItem("token");
          window.location.href = "/login";
          return;
        }
        throw new Error("Failed to submit form data.");
      }

      const result = await response.json();
      setNonTechnicalData(result.results);
      setNonTechnicalScore(result.score || 0);
      console.log("Non-technical data submitted:", result);
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setNonTechLoading(false);
    }
  };

  const goBack = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(0); // Navigate backward
    }
  };

  // Compute combined values for the ResultsTable
  const combinedResults = [...technicalData, ...nonTechnicalData];
  const totalScore = technicalScore + nonTechnicalScore;

  // Define pages in the multi-step flow
  const pages = [
    <InputForm onSubmit={handleCompanyFormSubmit} />, // Step 1: Input Form
    <Questions onSubmit={handleQuestionsFormSubmit} />, // Step 2: Questionnaire
    <div>
      {technicalLoading || nonTechnicalLoading ? (
        <div className="text-center my-8">
          <p>Loading results...</p>
          <div className="loader"></div> {/* Add loader component or spinner */}
        </div>
      ) : (
        <CyberinsuranceDashboard
          technicalScore={technicalScore}
          nonTechnicalScore={nonTechnicalScore}
          technicalResults={technicalData}
          nonTechnicalResults={nonTechnicalData}
        />
        // <ResultsTable
        //   results={combinedResults}
        //   score={totalScore}
        //   technicalScore={technicalScore}
        //   nonTechnicalScore={nonTechnicalScore}
        // />
      )}
    </div>, // Step 3: Results Table
  ];

  return (
    <>
      <Navbar />
      <section className="relative table w-full py-36 overflow-hidden bg-center bg-no-repeat bg-cover">
        <div className="flex justify-center items-center my-4">
          {/* Back Button */}
          {currentPageIndex > 0 && (
            <button onClick={goBack} className="btn btn-secondary">
              Restart
            </button>
          )}
          {/* Step Indicator */}
          {pages.map((_, index) => (
            <div
              key={index}
              className={`w-8 h-8 flex items-center justify-center rounded-full text-white mx-2 ${
                index === currentPageIndex
                  ? "bg-blue-600 font-bold"
                  : "bg-gray-300 hover:bg-gray-400"
              }`}
            >
              {index + 1}
            </div>
          ))}
        </div>
        {/* Page Content */}
        <div
          className={`flex transition-transform duration-1000 ease-in-out`}
          style={{ transform: `translateX(-${currentPageIndex * 100}%)` }}
        >
          {pages.map((Page, index) => (
            <div
              key={index}
              className="flex-shrink-0"
              style={{ flexBasis: "100%" }}
            >
              {Page}
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

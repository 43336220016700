"use client";

import { AlertTriangle, CheckCircle, XCircle, ShieldAlert } from "lucide-react";

export function CyberinsuranceDashboard({
  technicalScore,
  nonTechnicalScore,
  technicalResults,
  nonTechnicalResults,
}) {
  const overallScore = Math.round(technicalScore + nonTechnicalScore);

  // Mock data for cyber attacks
  const mockCyberAttacks = [
    { name: "Phishing", likelihood: 75 },
    { name: "Ransomware", likelihood: 60 },
    { name: "DDoS", likelihood: 45 },
  ];

  const getStatusFromScore = (score) => {
    if (score === 1.0) return "passed";
    if (score === 0.0) return "failed";
    return "failed";
  };

  const formatTechnicalChecks = () => {
    return technicalResults.map((result) => ({
      name: result.check.name,
      display_name: result.check.display_name,
      status: getStatusFromScore(result.result.raw_result),
      description: result.check.desc,
    }));
  };

  const formatNonTechnicalChecks = () => {
    return nonTechnicalResults.map((result) => ({
      question: result.check.desc,
      answer: result.result.raw_result === 1 ? "Yes" : "No",
      status:
        result.result.raw_result >= 0.7
          ? "High"
          : result.result.raw_result >= 0.5
          ? "Medium"
          : "Low",
    }));
  };

  return (
    <div className="space-y-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Overall Score Card */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="mb-4">
          <h2 className="text-2xl font-semibold">Overall Security Score</h2>
          {/* <p className="text-gray-600">
            Based on technical and non-technical assessments
          </p> */}
        </div>
        <div className="flex items-center space-x-4">
          <div className="w-full bg-gray-200 rounded-full h-4">
            <div
              className={
                overallScore >= 70
                  ? "bg-green-600 h-4 rounded-full transition-all duration-1000"
                  : overallScore >= 50
                  ? "bg-yellow-300 h-4 rounded-full transition-all duration-10000"
                  : "bg-red-600 h-4 rounded-full transition-all duration-1000"
              }
              style={{
                width: `${overallScore}%`,
                transition: "width 2s ease-in-out !important",
              }}
            ></div>
          </div>
          <span className="text-2xl font-bold">{overallScore}%</span>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        {/* Technical Checks Card */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="mb-4">
            <h2 className="text-2xl font-semibold">
              Technical Security Checks
            </h2>
            <p className="text-gray-600">
              Score: {Math.round(technicalScore)} / 10
            </p>
          </div>
          <div className="space-y-4">
            {formatTechnicalChecks().map((check) => (
              <div
                key={check.name}
                className="flex items-center justify-between"
              >
                <div>
                  <p className="font-medium">{check.display_name}</p>
                  <p className="text-sm text-gray-600">{check.description}</p>
                </div>
                <StatusBadge status={check.status} />
              </div>
            ))}
          </div>
        </div>

        {/* Non-Technical Assessment Card */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="mb-4">
            <h2 className="text-2xl font-semibold">Non-Technical Assessment</h2>
            <p className="text-gray-600">
              Score: {Math.round(nonTechnicalScore)} / 90
            </p>
          </div>
          <div className="space-y-4">
            {formatNonTechnicalChecks().map((item) => (
              <div key={item.question} className="space-y-1">
                <p className="font-medium">{item.question}</p>
                <div className="flex items-center justify-between">
                  <p className="text-sm">{item.answer}</p>
                  <QuestionStatusBadge status={item.status} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Cyber Attacks Card */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="mb-4">
          <h2 className="text-2xl font-semibold">Most Likely Cyber Attacks</h2>
          <p className="text-gray-600">Based on your security profile</p>
        </div>
        <div className="space-y-4">
          {mockCyberAttacks.map((attack) => (
            <div
              key={attack.name}
              className="flex items-center justify-between"
            >
              <div className="flex items-center space-x-2">
                <ShieldAlert className="w-5 h-5 text-yellow-500" />
                <span className="font-medium">{attack.name}</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-24 bg-gray-200 rounded-full h-2">
                  <div
                    className={`${
                      attack.likelihood >= 70
                        ? "bg-red-700"
                        : attack.likelihood >= 50
                        ? "bg-red-500"
                        : "bg-yellow-300"
                    } h-2 rounded-full transition-all`}
                    style={{ width: `${attack.likelihood}%` }}
                  ></div>
                </div>
                <span className="text-sm font-medium">
                  {attack.likelihood}%
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function StatusBadge({ status }) {
  const baseClasses =
    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium";

  switch (status) {
    case "passed":
      return (
        <span className={`${baseClasses} bg-green-300 text-green-800`}>
          <CheckCircle className="w-4 h-4 mr-1" /> Passed
        </span>
      );
    case "failed":
      return (
        <span className={`${baseClasses} bg-red-100 text-red-800`}>
          <XCircle className="w-4 h-4 mr-1" /> Failed
        </span>
      );
    case "warning":
      return (
        <span className={`${baseClasses} bg-yellow-100 text-yellow-800`}>
          <AlertTriangle className="w-4 h-4 mr-1" /> Warning
        </span>
      );
  }
}

function QuestionStatusBadge({ status }) {
  const baseClasses =
    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium";

  switch (status) {
    case "High":
      return (
        <span className={`${baseClasses} bg-green-300 text-green-800`}>
          High
        </span>
      );
    case "Medium":
      return (
        <span className={`${baseClasses} bg-red-100 text-red-800`}>Medium</span>
      );
    case "Low":
      return (
        <span className={`${baseClasses} bg-yellow-100 text-yellow-800`}>
          Low
        </span>
      );
  }
}

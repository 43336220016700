import React from "react";
import "../../assets/libs/@mdi/font/css/materialdesignicons.min.css";
import "../../assets/css/tailwind.css";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Navbar(props) {
  let { navClass, navJustify } = props;
  let [isMenu, setisMenu] = useState(false);
  let [manu, setManu] = useState("");
  let [isAuthenticated, setIsAuthenticated] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check authentication status when component mounts and when token changes
    const checkAuth = () => {
      const token = localStorage.getItem("token");
      setIsAuthenticated(!!token);
    };

    checkAuth();
    window.addEventListener("storage", checkAuth);

    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  useEffect(() => {
    let current = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    console.log(current);
    setManu(current);

    function windowScroll() {
      const navbar = document.getElementById("topnav");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        if (navbar !== null) {
          navbar?.classList.add("nav-sticky");
        }
      } else {
        if (navbar !== null) {
          navbar?.classList.remove("nav-sticky");
        }
      }
    }
    window.addEventListener("scroll", windowScroll);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, [location.pathname.substring(location.pathname.lastIndexOf("/") + 1)]);

  const toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/");
  };

  return (
    <nav
      id="topnav"
      className={`defaultscroll ${
        navClass === "nav-light"
          ? ""
          : navClass === "nav-sticky"
          ? "bg-white dark:bg-slate-900"
          : ""
      }`}
    >
      <div className="container relative">
        {navClass === "nav-light" ? (
          <Link className="logo" to="/">
            <span className="inline-block dark:hidden">
              <img
                src={"/logo-dark.png"}
                className="l-dark"
                style={{ height: "24px", width: "auto" }}
                alt=""
              />
              <img
                src={"/logo-dark.png"}
                className="l-light"
                style={{ height: "24px", width: "auto" }}
                alt=""
              />
            </span>
            <img
              src={"/logo-dark.png"}
              height="24"
              className="hidden dark:inline-block"
              alt=""
            />
          </Link>
        ) : (
          <Link className="logo" to="/">
            <img
              src={"/logo-dark.png"}
              style={{ height: "24px", width: "auto" }}
              className="inline-block dark:hidden"
              alt=""
            />
            <img
              src={"/logo-dark.png"}
              style={{ height: "24px", width: "auto" }}
              className="hidden dark:inline-block"
              alt=""
            />
          </Link>
        )}

        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              className={`navbar-toggle ${isMenu ? "open" : ""}`}
              id="isToggle"
              onClick={() => toggleMenu()}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
          <div className="flex items-center">
            <div className="w-1/6"></div>

            <ul
              className={`navigation-menu ${navClass} ${navJustify} flex-1 flex justify-center`}
            >
              <li className={manu === "" ? "active" : ""}>
                <Link to="/" className="sub-menu-item">
                  Home
                </Link>
              </li>
              <li className={manu === "about" || "" ? "active" : ""}>
                <Link to="/about" className="sub-menu-item">
                  About Us
                </Link>
              </li>
              <li className={manu === "team" || "" ? "active" : ""}>
                <Link to="/team" className="sub-menu-item">
                  Team
                </Link>
              </li>
              <li className={manu === "blog" || "" ? "active" : ""}>
                <Link to="/blog" className="sub-menu-item">
                  Blog
                </Link>
              </li>
              <li className={manu === "contact" || "" ? "active" : ""}>
                <Link to="/contact" className="sub-menu-item">
                  Contact
                </Link>
              </li>
            </ul>

            <div className="w-1/6 flex justify-end">
              {isAuthenticated ? (
                <button
                  onClick={handleLogout}
                  className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                >
                  Logout
                </button>
              ) : (
                <Link
                  to="/login"
                  className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

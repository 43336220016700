import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BACKEND_URL } from "../../data/urls";

const Questionnaire = ({ onSubmit }) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch questions from both APIs
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // Fetch radio questions
        const radioResponse = await fetch(
          `${BACKEND_URL}/checks/?skip=0&limit=100&section=Non-Technical`
        );
        if (!radioResponse.ok)
          throw new Error("Failed to fetch radio questions.");
        const radioQuestions = await radioResponse.json();

        // Transform radio questions
        const formattedRadioQuestions = radioQuestions.data.map((q) => ({
          id: q.id,
          label: q.desc,
          section_id: q.section_id,
          name: q.name,
          type: "Yes/No",
        }));

        // Fetch text questions
        const textResponse = await fetch(
          `${BACKEND_URL}/checks/?skip=0&limit=100&section=AI`
        );
        if (!textResponse.ok)
          throw new Error("Failed to fetch text questions.");
        const textQuestions = await textResponse.json();

        // Transform text questions
        const formattedTextQuestions = textQuestions.data.map((q) => ({
          id: q.id,
          label: q.desc,
          section_id: q.section_id,
          name: q.name,
          type: "Text",
        }));

        // Combine both question sets
        setQuestions([...formattedRadioQuestions, ...formattedTextQuestions]);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  // Create Yup validation schema dynamically
  const createValidationSchema = () => {
    const schemaShape = {};
    questions.forEach((question) => {
      if (question.type === "Yes/No") {
        schemaShape[question.id] = Yup.string()
          .required(`${question.label} is required.`)
          .oneOf(["Yes", "No"], "Invalid response.");
      } else if (question.type === "Text") {
        schemaShape[question.id] = Yup.string().required(
          `${question.label} is required.`
        );
      }
    });
    return Yup.object().shape(schemaShape);
  };

  // Initial values for Formik
  const initialValues = questions.reduce((values, question) => {
    values[question.id] = ""; // Use question.id as the key
    return values;
  }, {});

  if (loading) {
    return <p>Loading questions...</p>;
  }

  if (error) {
    return <p className="text-red-500">Error: {error}</p>;
  }

  return (
    <div className="container-fluid relative">
      <div className="grid lg:grid-cols-12 grid-cols-1">
        {/* Center content in the middle 10 columns */}
        <div className="lg:col-span-10 lg:col-start-2 col-span-12">
          <div className="layout-specing">
            <Formik
              initialValues={initialValues}
              validationSchema={createValidationSchema()}
              onSubmit={(values, { resetForm }) => {
                onSubmit(values);
                resetForm();
              }}
            >
              {({ isSubmitting }) => (
                <Form className="p-4 border rounded shadow-md">
                  {/* Render Questions Dynamically */}
                  {questions.map((question) => (
                    <div className="mb-4" key={question.id}>
                      <label
                        htmlFor={question.id}
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        {question.label}
                      </label>
                      {question.type === "Yes/No" ? (
                        <div
                          role="group"
                          aria-labelledby={question.id}
                          className="flex gap-4"
                        >
                          <label className="flex items-center gap-2">
                            <Field
                              type="radio"
                              name={question.id} // Use question.id as the key
                              value="Yes"
                              className="form-radio h-5 w-5 text-blue-600"
                            />
                            <span>Yes</span>
                          </label>
                          <label className="flex items-center gap-2">
                            <Field
                              type="radio"
                              name={question.id} // Use question.id as the key
                              value="No"
                              className="form-radio h-5 w-5 text-blue-600"
                            />
                            <span>No</span>
                          </label>
                        </div>
                      ) : question.type === "Text" ? (
                        <Field
                          id={question.id}
                          name={question.id} // Use question.id as the key
                          as="textarea"
                          className="mt-1 block w-full border rounded-md p-2"
                          placeholder="Enter your response"
                        />
                      ) : null}
                      <ErrorMessage
                        name={question.id} // Use question.id for validation
                        component="p"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  ))}

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white font-medium rounded hover:bg-blue-700"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
